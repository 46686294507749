import React from 'react'
import Layout from "../components/layout";
import LogoBig from "../img/logobig.inline.svg";

const NotFoundPage = () => (
    <Layout>
        <div className={'max-w-sm mx-auto mt-8 text-center'}>
            <LogoBig/>
            <div className={'text-2xl font-semibold mt-8'}>Seite nicht gefunden!</div>
            <div className={'mt-4'}>
                <a className="rounded bg-primary-500 text-white px-4 py-2" href="/"
                >Zurück zur Homepage</a>
            </div>
        </div>
    </Layout>
)

export default NotFoundPage
